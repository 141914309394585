import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ButtonComponent, TrackByPipe } from '@dougs/ds';
import { FieldComponent } from '@dougs/fields/ui';
import { Task, TaskFormInlineGroup } from '@dougs/task/dto';

@Component({
  selector: 'dougs-task-form-fields',
  templateUrl: './task-form-fields.component.html',
  styleUrls: ['./task-form-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, NgFor, FieldComponent, NgIf, ButtonComponent, TrackByPipe, NgClass],
})
export class TaskFormFieldsComponent implements AfterViewInit {
  @Input() taskFormInlineGroup!: TaskFormInlineGroup;
  @Input() task!: Task;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() disabledCancel = false;
  @Input() readonly = false;

  @Output() changeTaskForm: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitTaskForm: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancelTaskForm: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeTaskForm: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('form') ngform!: NgForm;

  ngAfterViewInit(): void {
    if (this.disabledCancel) {
      const hasBeenTouched: boolean = (this.taskFormInlineGroup?.items ?? [])
        .map((item) => item.value)
        .some((inlineFormValue) => !!inlineFormValue);
      if (hasBeenTouched) {
        this.ngform.form.markAsDirty();
      }
    }
  }

  changeForm(): void {
    if (this.taskFormInlineGroup && this.taskFormInlineGroup.enableFormChangeUpdate) {
      this.changeTaskForm.emit();
    }
  }

  submitForm(): void {
    this.submitTaskForm.emit();

    if (this.ngform) {
      this.ngform.form.markAsPristine();
    }
  }

  cancelForm(): void {
    this.cancelTaskForm.emit();

    if (this.ngform) {
      this.ngform.form.markAsPristine();
    }
  }

  closeForm(): void {
    this.closeTaskForm.emit();
  }
}
